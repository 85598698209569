@import url(https://fonts.googleapis.com/css?family=Inter&display=medium);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.OutsideWrapper {
  background-color: #f6f3e7;
  display: flex;
  justify-content: center;
  overflow: hidden;
  font-family: 'Inter',  'sans-serif';
  font-weight: 600;
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 1);
}
.AppWrapper {
  width: 100%;
  max-width: 30rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  min-height: -webkit-fill-available;
  height: 100vh;
  height: 100dvh;
}
.App {
  overflow: hidden;
  flex-grow: 1;
  width: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.playerWrapper  {
  position: absolute;
  width: 100vh;
  height: 100vh;
  z-index: 0;
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0;
  margin: 0;
}

.LandingWrapper_Outside {
  overflow: hidden;
  background-color: #FFFF;
  display: flex;
  padding-top: 150px;
  padding-bottom: 67px;
  justify-content: space-between;
  position: relative;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.LandingPage_Circle {
  position: absolute;
  background-color: #FF8A3B;
  width: 175%;
  left: auto;
  top: auto;
  right: -39%;
  bottom: 0vh;
  padding-bottom: 120%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  box-flex: center;

}

.LandingWrapper_Logo {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  grid-column-gap: 32px;
  justify-content: center;
  bottom: 2rem;
}

.LandingPage_TUI {
  height: 64px;
}

.LandingPage_UnmazedLogo {
  height: 64px;
}

.LandingWrapper_Text {
  z-index: 5;
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  align-items: center;
  pointer-events: none;
}

.LandingPage_UnmazedText {
  z-index: 5;
  pointer-events: none;
  padding-bottom: 0;
  height: 16px;
  color: #FFF;
}

.LandingPage_Text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 0px;
  text-align: center;
  color: #FFFF;
}

.LandingPage_GoButton {
  position: absolute;
  z-index: 6;
  color: #000;
  top: 22%;
  left: 50%;
  right: auto;
  bottom: auto;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}




.toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 30rem;
  height: 6vh;
  height: 6dvh;
  background-color: #ffff;
  padding-top: 8px;
  z-index: 1;
}

.toolbar button {
  flex: 1 1;
  background-color: #ffff;
  border: 1px solid #ffff;
  height: 100%;
  width: 33%;
  cursor: pointer;
  align-items: center;
}

.toolbarImage {
  pointer-events: none;
}
.toolbarText {
  font-family: 'Inter';
  pointer-events: none;
  color: #a3a3a3;
}
.returnButtonImage {
  pointer-events: none;
  top: 30%;
  height: 50%;
  width: 50%;
  align-items: center;
}

.ReturnButton {
  -webkit-transform: translate(0px, 20px);
          transform: translate(0px, 20px);
}
.SliderContainer {
  position: absolute;
  z-index: 1;
  border-radius: 21px;
  background-color: white;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
  bottom: 0;
  right: 0;
  margin-right: 30px;
  margin-bottom: 2rem;
}
.SliderIcon {
  width: 100%;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  height: 100%;
  pointer-events: none;
}
.SliderIcon #Add{
  margin-top: 10px;
}
.SearchBarContainer {
  margin-top: 5%;
  height: 50px;
  z-index: 1;
  width: 80%;
}
.tutorialTextbox {
  pointer-events: all !important;
  position: absolute;
  max-width: 10rem;
  z-index: 2;
  font-weight: 400;
  background-color: white;
  border-radius:  21px;
  border-bottom-left-radius: 0;
  font-family: 'Inter';
  pointer-events: none;
  color: #a3a3a3;
  padding: 1rem;
  top: 30%;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

 #id1.tutorialTextbox {
  border-radius:  21px;
  border-bottom-right-radius: 0;
  right: 17% !important;
}
.textboxButton {
  z-index: 2;
  font-family: 'Inter';
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 1.5rem;
}
.SearchBar {
  font-family: 'Inter', 'sans-serif';
  height: 100%;
  width: 100%;
  font-size: 20px;
  color: #a3a3a3;
  border: none;
  border-radius: 27.5px;
  padding: 12px 20px 12px 60px;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
  background-color: none;
  background-image: url(/static/media/Search_Orange.ecde901e.svg);
  background-position: 20px 15px; 
  background-repeat: no-repeat;
}
.SearchBar:focus {
  border: 0;
  outline: none;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.toolbarText#GoText {
  color: #FF8A3B;
}
